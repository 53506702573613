import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css';

function Login({ setLoggedIn }) {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:5001/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // Store user info and token in localStorage
        if (data.token) {
          localStorage.setItem('token', data.token);
        }
        localStorage.setItem('userEmail', data.email);
        localStorage.setItem('userName', data.name);

        // Update global loggedIn state
        if (setLoggedIn) {
          setLoggedIn(true);
        }

        // Navigate to job-seeker dashboard without state
        navigate('/job-seeker');
      } else {
        setMessage(data.error || 'Login failed');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred during login');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="login-left">
          <h2>Discover Your Next Opportunity</h2>
          <p>Join a thriving community of professionals, recruiters, and employers:</p>
          <ul className="benefits-list">
            <li>✔ Network with industry leaders</li>
            <li>✔ Stay updated with the latest trends</li>
            <li>✔ Personalized recommendations and insights</li>
            <li>✔ Showcase your authentic self</li>
          </ul>
        </div>
        <div className="login-right">
          <h2>Welcome Back</h2>
          {message && <p className="message">{message}</p>}
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input 
                type="email" 
                id="email" 
                name="email" 
                value={formData.email}
                onChange={handleChange}
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input 
                type="password" 
                id="password" 
                name="password"
                value={formData.password}
                onChange={handleChange}
                required 
              />
            </div>
            <button type="submit" className="login-button">Login</button>
          </form>
          <p className="register-link">Don't have an account? <Link to="/register">Sign up</Link></p>
          <p className="forgot-password-link"><Link to="/forgot-password">Forgot Password?</Link></p>
        </div>
      </div>
    </div>
  );
}

export default Login;
