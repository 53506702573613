import React, { useState, useEffect } from "react";
import "./Pitches.css";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";

function Pitches() {
  const [pitches, setPitches] = useState([]);

  // Filters
  const [searchTerm, setSearchTerm] = useState("");
  const [jobFilter, setJobFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [skillsFilter, setSkillsFilter] = useState("");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pitchesPerPage = 6;

  // Favorites
  const [favorites, setFavorites] = useState({});

  // Fetch job seekers on component mount
  useEffect(() => {
    const fetchJobSeekers = async () => {
      try {
        const response = await fetch("http://localhost:5001/api/job_seekers");
        if (!response.ok) {
          throw new Error("Failed to fetch job seekers");
        }
        const data = await response.json();
        setPitches(data);
      } catch (error) {
        console.error("Error fetching job seekers:", error);
      }
    };

    fetchJobSeekers();
  }, []);

  // Filter logic
  const filteredPitches = pitches.filter((user) => {
    // Name filter
    const nameMatch = user.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    // Job filter => user.currentrole (case-insensitive)
    const userCurrentRole = (user.currentrole || "").toLowerCase();
    const jobMatch = jobFilter
      ? userCurrentRole === jobFilter.toLowerCase()
      : true;

    // Location filter => combine city + state
    const locationString = user.state
      ? `${user.city}, ${user.state}`.toLowerCase()
      : (user.city || "").toLowerCase();
    const locationMatch = locationFilter
      ? locationString.includes(locationFilter.toLowerCase())
      : true;

    // Skills filter => user.skills
    const userSkills = (user.skills || "").toLowerCase();
    const skillsMatch = skillsFilter
      ? userSkills.includes(skillsFilter.toLowerCase())
      : true;

    return nameMatch && jobMatch && locationMatch && skillsMatch;
  });

  // Pagination
  const indexOfLastPitch = currentPage * pitchesPerPage;
  const indexOfFirstPitch = indexOfLastPitch - pitchesPerPage;
  const currentPitches = filteredPitches.slice(
    indexOfFirstPitch,
    indexOfLastPitch
  );
  const totalPages = Math.ceil(filteredPitches.length / pitchesPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle favorites
  const toggleFavorite = (id) => {
    setFavorites((prevFavorites) => ({
      ...prevFavorites,
      [id]: !prevFavorites[id],
    }));
  };

  // Clear filters
  const clearFilters = () => {
    setSearchTerm("");
    setJobFilter("");
    setLocationFilter("");
    setSkillsFilter("");
    setCurrentPage(1);
  };

  return (
    <div className="pitches-container">
      <div className="pitches-header">
        <h1>Browse Pitches</h1>
      </div>

      <div className="filter-container">
        <select
          value={jobFilter}
          onChange={(e) => setJobFilter(e.target.value)}
        >
          <option value="">Filter by current job</option>
          <option value="Software Engineer">Software Engineer</option>
          <option value="Graphic Designer">Graphic Designer</option>
          <option value="Front-End Developer">Front-End Developer</option>
        </select>

        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <input
          type="text"
          placeholder="Filter by location..."
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
        />

        <input
          type="text"
          placeholder="Filter by skills..."
          value={skillsFilter}
          onChange={(e) => setSkillsFilter(e.target.value)}
        />

        <button className="clear-filters-button" onClick={clearFilters}>
          Clear All Filters
        </button>
      </div>

      <div className="pitches-content">
        <div className="pitches-grid">
          {currentPitches.map((user) => {
            // Combine city + state for display
            const userLocation = user.state
              ? `${user.city}, ${user.state}`
              : user.city || "";

            return (
              <div className="pitch-card" key={user.id}>
                <p>No pitch video available</p>

                <div className="pitch-info">
                  <div>
                    <h3>{user.name}</h3>
                    <p>
                      <strong>Job:</strong> {user.currentrole || "N/A"}
                    </p>
                    <p>
                      <strong>Skills:</strong> {user.skills || "N/A"}
                    </p>
                    <p>
                      <strong>Location:</strong> {userLocation || "N/A"}
                    </p>
                    <p>{user.shortBio}</p>
                  </div>
                  <div className="pitch-actions">
                    <Link
                      to={`/profile/${user.id}`}
                      className="view-profile-button"
                    >
                      View Profile
                    </Link>
                    <button
                      className={`favorite-btn ${
                        favorites[user.id] ? "favorited" : ""
                      }`}
                      onClick={() => toggleFavorite(user.id)}
                    >
                      <FaStar />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            className={`pagination-button ${
              currentPage === index + 1 ? "active" : ""
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Pitches;
