// src/components/Register.jsx

import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Register.css'

// 1. Create a sorted array of desired roles
const DESIRED_ROLE_OPTIONS = [
  'Software Engineer',
  'Front-End Developer',
  'Back-End Developer',
  'Full-Stack Developer',
  'Data Scientist',
  'Data Engineer',
  'DevOps Engineer',
  'Cybersecurity Specialist',
  'QA Engineer',
  'Product Manager',
  'UI/UX Designer',
  'Cloud Engineer',
  'Mobile App Developer',
  'Solutions Architect',
  'Graphic Designer'
].sort()

// 2. Create a sorted array of states
const STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 
  'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 
  'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 
  'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 
  'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 
  'VA', 'WA', 'WV', 'WI', 'WY'
].sort()

function Register({ setLoggedIn }) {
  const navigate = useNavigate()

  // Step state: 0 => basic info, 1 => additional info
  const [step, setStep] = useState(0)

  // Combine all form data into one object
  const [formData, setFormData] = useState({
    // Step 0 fields
    name: '',
    email: '',
    password: '',

    // Step 1 fields
    desiredRole: '',
    city: '',
    state: '',
    skills: '',
    shortBio: '',
    currentrole: '',
  })

  const [message, setMessage] = useState('')

  // Handle all changes in a single function
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  // Step navigation
  const nextStep = () => setStep(1)
  const prevStep = () => setStep(0)

  // Final submission (runs at step=1)
  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      // POST all fields to your /api/register
      const response = await fetch('http://localhost:5001/api/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          // Step 0 fields
          name: formData.name,
          email: formData.email,
          password: formData.password,

          // Step 1 fields
          desiredRole: formData.desiredRole,
          city: formData.city,
          state: formData.state,
          skills: formData.skills,
          shortBio: formData.shortBio,
          currentrole: formData.currentrole,
        }),
      })

      const data = await response.json()

      if (response.ok) {
        setMessage('Registration successful!')

        // If the server returns a token, store it in localStorage
        if (data.token) {
          localStorage.setItem('token', data.token)
        }

        // Store user info in localStorage so the user can be recognized
        localStorage.setItem('userEmail', data.user.email)
        localStorage.setItem('userName', data.user.name)

        // If you have a setLoggedIn function, update the app state
        if (setLoggedIn) {
          setLoggedIn(true)
        }

        // Redirect to the job-seeker page (dashboard)
        navigate('/job-seeker')
      } else {
        // If server responded with an error
        setMessage(data.error || 'Registration failed')
      }
    } catch (error) {
      console.error('Error:', error)
      setMessage('An error occurred during registration')
    }
  }

  return (
    <div className="register-container">
      <div className="register-box">
        {/* Left side content can remain constant */}
        <div className="register-left">
          <h2>Discover Your Next Opportunity</h2>
          <p>Join a thriving community of professionals, recruiters, and employers:</p>
          <ul className="benefits-list">
            <li>✔ Network with industry leaders</li>
            <li>✔ Stay updated with the latest trends</li>
            <li>✔ Personalized recommendations and insights</li>
            <li>✔ Showcase your authentic self</li>
          </ul>
        </div>

        {/* Right side content changes based on step */}
        <div className="register-right">
          <h2>Sign Up Today</h2>
          {message && <p className="message">{message}</p>}

          {step === 0 && (
            <form onSubmit={(e) => e.preventDefault()} className="register-form">
              {/* Basic Info */}
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>

              <button
                type="button"
                className="register-button"
                onClick={nextStep}
              >
                Next
              </button>

              <p className="sign-in-link">
                Have an account? <Link to="/login">Sign in</Link>
              </p>
            </form>
          )}

          {step === 1 && (
            <form onSubmit={handleSubmit} className="register-form">
              {/* Additional Info */}
              <div className="form-group">
                <label htmlFor="desiredRole">Desired Role</label>
                <select
                  id="desiredRole"
                  name="desiredRole"
                  value={formData.desiredRole}
                  onChange={handleChange}
                  required
                >
                  <option value="">--Select a Desired Role--</option>
                  {DESIRED_ROLE_OPTIONS.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="state">State</label>
                <select
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                >
                  <option value="">--Select a State--</option>
                  {STATES.map((st) => (
                    <option key={st} value={st}>
                      {st}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="skills">Skills</label>
                <input
                  type="text"
                  id="skills"
                  name="skills"
                  value={formData.skills}
                  onChange={handleChange}
                  placeholder="e.g. JavaScript, React, Node.js"
                />
              </div>

              <div className="form-group">
                <label htmlFor="shortBio">Short Bio</label>
                <textarea
                  id="shortBio"
                  name="shortBio"
                  value={formData.shortBio}
                  onChange={handleChange}
                  placeholder="Tell us a bit about yourself..."
                />
              </div>

              <div className="form-group">
                <label htmlFor="currentrole">Current Role</label>
                <input
                  type="text"
                  id="currentrole"
                  name="currentrole"
                  value={formData.currentrole}
                  onChange={handleChange}
                  placeholder="e.g. Junior Developer"
                />
              </div>

              <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
                <button
                  type="button"
                  className="register-button"
                  style={{ backgroundColor: '#ccc', color: '#333' }}
                  onClick={prevStep}
                >
                  Previous
                </button>

                <button type="submit" className="register-button">
                  Register
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default Register
