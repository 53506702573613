// src/App.js
import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Hero from "./components/Hero/Hero";
import Navbar from "./components/Navbar/Navbar";
import FeaturedPitches from "./components/FeaturedPitches/FeaturedPitches";
// import TestimonialCarousel from './components/Testimonial/TestimonialCarousel';
import Benefits from "./components/Benefits/Benefits";
import Footer from "./components/Footer/Footer";
import Pitches from "./components/Pitches/Pitches";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import JobSeekerDashboard from "./components/JobSeeker/JobSeekerDashboard.jsx";
import Separator from "./components/Separator/Separator";
import GetStarted from "./components/GetStarted/GetStarted";
import UserList from "./components/UserList/UserList.jsx";
import PublicProfile from "./components/PublicProfile/PublicProfile.jsx";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogout = () => {
    // Clear any auth tokens or session data
    setLoggedIn(false);
    // Optionally redirect to home
  };

  return (
    <Router>
      <div className="App">
        <Navbar loggedIn={loggedIn} onLogout={handleLogout} />
        <div className="content">
          <Routes>
            {/* Landing/Home */}
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <Separator />
                  <Benefits />
                  <FeaturedPitches />
                  <GetStarted />
                </>
              }
            />

            {/* Single route for /pitches */}
            <Route path="/pitches" element={<Pitches />} />

            {/* About/Contact */}
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />

            {/* Auth */}
            <Route
              path="/login"
              element={<Login setLoggedIn={setLoggedIn} />}
            />
            <Route
              path="/register"
              element={<Register setLoggedIn={setLoggedIn} />}
            />

            {/* Logged-in user’s own dashboard */}
            <Route
              path="/job-seeker"
              element={<JobSeekerDashboard loggedIn={loggedIn} />}
            />

            {/* Public user profile by ID */}
            <Route path="/profile/:userId" element={<PublicProfile />} />

            {/* Other */}
            <Route path="/userlist" element={<UserList />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
