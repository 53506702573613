import React, { useState, useEffect } from 'react';
import './JobSeekerDashboard.css';
import { FaCamera, FaVideo } from 'react-icons/fa';

const JobSeekerDashboard = () => {
  const email = localStorage.getItem('userEmail');
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const [showPitchUpload, setShowPitchUpload] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!email) {
        setError('Email is missing.');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `http://localhost:5001/api/user?email=${encodeURIComponent(email)}`
        );
        const data = await response.json();

        if (response.ok) {
          setUserData(data.user);
        } else {
          setError(data.error || 'Failed to fetch user data.');
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('An error occurred while fetching user data.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [email]);

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (!file || !email) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('email', email);

    fetch('http://localhost:5001/api/upload', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success && userData) {
          setUserData({ ...userData, profilePicture: data.imageUrl });
        } else {
          alert('Failed to upload image');
        }
      })
      .catch((err) => {
        console.error('Error uploading file:', err);
        alert('Error uploading file');
      });
  };

  // Handle Elevator Pitch Video Upload
  const handlePitchVideoUpload = (event) => {
    const file = event.target.files[0];
    if (!file || !email) return;

    const formData = new FormData();
    formData.append('pitchVideo', file);
    formData.append('email', email);

    fetch('http://localhost:5001/api/upload-pitch', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success && userData) {
          setUserData({ ...userData, pitchVideoUrl: data.videoUrl });
          setShowPitchUpload(false);
        } else {
          alert('Failed to upload pitch video');
        }
      })
      .catch((err) => {
        console.error('Error uploading pitch video:', err);
        alert('Error uploading pitch video');
      });
  };

  if (loading) return <div>Loading user data...</div>;
  if (error) return <div>{error}</div>;

  // Fallback fields
  const shortBio = userData.shortBio || 'Passionate developer eager to build scalable solutions.';
  const location =
    userData.city && userData.state
      ? `${userData.city}, ${userData.state}`
      : 'Your City, State';

  // Renamed fields in your DB and code:
  //  - desiredRole (instead of jobType)
  //  - currentrole (instead of desiredJob)
  const desiredRole = userData.desiredRole || 'Not Specified';
  const currentrole = userData.currentrole || 'Not Specified';

  let skillsList = userData.skills;
  if (!skillsList) {
    skillsList = ['JavaScript', 'React', 'Node.js'];
  } else if (typeof skillsList === 'string') {
    skillsList = skillsList.split(',').map((s) => s.trim());
  } else if (!Array.isArray(skillsList)) {
    skillsList = ['JavaScript', 'React', 'Node.js'];
  }

  return (
    <div className="jsd-container">
      <div className="jsd-card">
        <div className="jsd-card-header">
          <h2 className="jsd-card-title">Your Profile</h2>
          <button className="jsd-edit-btn">
            <FaCamera className="jsd-edit-icon" />
            Edit Profile
          </button>
        </div>
        <div className="jsd-card-content">
          <div className="jsd-content-row">
            <div className="jsd-avatar-section">
              <div className="jsd-avatar">
                {userData.profilePicture ? (
                  <img
                    src={userData.profilePicture}
                    alt={userData.name || 'User'}
                    className="jsd-avatar-img"
                  />
                ) : (
                  // If no profile picture, just show a big camera icon
                  <FaCamera className="jsd-avatar-placeholder" />
                )}

                {/* The small upload icon at the bottom corner */}
                <div className="jsd-avatar-upload">
                  <FaCamera />
                  <input type="file" accept="image/*" onChange={handlePhotoUpload} />
                </div>
              </div>
            </div>
            <div className="jsd-user-info">
              <h2 className="jsd-user-name">{userData.name}</h2>
              <p className="jsd-user-bio">{shortBio}</p>
              <div className="jsd-user-detail">
                <span className="jsd-detail-icon">📍</span>
                <span>{location}</span>
              </div>

              {/* Current Role */}
              <div className="jsd-user-detail">
                <span className="jsd-detail-icon">👔</span>
                <span>
                  <strong>Current Role:</strong> {currentrole}
                </span>
              </div>

              {/* Desired Role */}
              <div className="jsd-user-detail">
                <span className="jsd-detail-icon">💼</span>
                <span>
                  <strong>Desired Role:</strong> {desiredRole}
                </span>
              </div>

              <div className="jsd-section">
                <h3 className="jsd-section-title">Skills</h3>
                <div className="jsd-skills">
                  {skillsList.map((skill, index) => (
                    <span key={index} className="jsd-badge">
                      {skill}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Elevator Pitch Section */}
      <div className="jsd-card jsd-pitch-card">
        <div className="jsd-card-header">
          <h2 className="jsd-card-title">Your Elevator Pitch</h2>
          <button
            className="jsd-edit-btn"
            onClick={() => setShowPitchUpload(!showPitchUpload)}
          >
            <FaVideo className="jsd-edit-icon" />
            {userData.pitchVideoUrl ? 'Edit Pitch' : 'Add Pitch'}
          </button>
        </div>
        <div className="jsd-card-content">
          {userData.pitchVideoUrl ? (
            <video className="jsd-pitch-video" controls src={userData.pitchVideoUrl}>
              Your browser does not support the video tag.
            </video>
          ) : (
            <p>No pitch uploaded yet.</p>
          )}

          {showPitchUpload && (
            <div className="jsd-pitch-upload-section">
              <input type="file" accept="video/*" onChange={handlePitchVideoUpload} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobSeekerDashboard;
