import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./PublicProfile.css"; // new style file
import { FaCamera } from "react-icons/fa"; // for avatar placeholder or icons

function PublicProfile() {
  const { userId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      if (!userId) return; // If no param
      try {
        const response = await fetch(
          `http://localhost:5001/api/user-by-id?id=${encodeURIComponent(
            userId
          )}`
        );
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || "Failed to fetch profile data");
        }
        setProfileData(data.user);
      } catch (err) {
        console.error(err);
        setError("Could not load profile");
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [userId]);

  if (loading) return <div>Loading profile...</div>;
  if (error) return <div>{error}</div>;
  if (!profileData) return <div>No profile found.</div>;

  // Prepare fallback fields or transformations, similar to JobSeekerDashboard:
  const userName = profileData.name || "Anonymous User";
  const userShortBio = profileData.short_bio || "No biography provided.";
  const userCity = profileData.city || "Unknown City";
  const userState = profileData.state || "Unknown State";
  const userLocation = `${userCity}, ${userState}`;

  const currentRole = profileData.currentrole || "N/A";
  const desiredRole = profileData.desired_role || "N/A";

  // Handle skills as either string or array
  let skillsList = profileData.skills;
  if (!skillsList) {
    skillsList = [];
  } else if (typeof skillsList === "string") {
    skillsList = skillsList.split(",").map((s) => s.trim());
  }

  // If we store a profile picture url or not
  const profilePic = profileData.profilePicture || null;

  return (
    <div className="pp-container">
      <div className="pp-card">
        <div className="pp-card-header">
          <h2 className="pp-card-title">{userName}</h2>
        </div>
        <div className="pp-card-content">
          <div className="pp-content-row">
            {/* Avatar */}
            <div className="pp-avatar-section">
              <div className="pp-avatar">
                {profilePic ? (
                  <img
                    src={profilePic}
                    alt={userName}
                    className="pp-avatar-img"
                  />
                ) : (
                  <FaCamera className="pp-avatar-placeholder" />
                )}
              </div>
            </div>

            {/* Info */}
            <div className="pp-user-info">
              {/* Short bio */}
              <p className="pp-user-bio">{userShortBio}</p>

              {/* Location */}
              <div className="pp-user-detail">
                <span className="pp-detail-icon">📍</span>
                <span>{userLocation}</span>
              </div>

              {/* Current Role */}
              <div className="pp-user-detail">
                <span className="pp-detail-icon">👔</span>
                <span>
                  <strong>Current Role:</strong> {currentRole}
                </span>
              </div>

              {/* Desired Role */}
              <div className="pp-user-detail">
                <span className="pp-detail-icon">💼</span>
                <span>
                  <strong>Desired Role:</strong> {desiredRole}
                </span>
              </div>

              {/* Skills */}
              <div className="pp-section">
                <h3 className="pp-section-title">Skills</h3>
                <div className="pp-skills">
                  {skillsList.length > 0 ? (
                    skillsList.map((skill, idx) => (
                      <span key={idx} className="pp-badge">
                        {skill}
                      </span>
                    ))
                  ) : (
                    <span className="pp-badge">No skills provided</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* If user’s pitch video is publicly accessible, you could add a second card
          similar to the "Elevator Pitch" section. */}
    </div>
  );
}

export default PublicProfile;
