import React, { useState, useEffect } from "react";
import "../Pitches/Pitches.css";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";

function FeaturedPitches() {
  const [featured, setFeatured] = useState([]);
  const [favorites, setFavorites] = useState({});

  useEffect(() => {
    const fetchRandomFeatured = async () => {
      try {
        const response = await fetch("http://localhost:5001/api/job_seekers");
        if (!response.ok) {
          throw new Error("Failed to fetch job seekers");
        }
        const allJobSeekers = await response.json();

        // Shuffle array in place (Fisher–Yates or similar)
        const shuffled = shuffleArray(allJobSeekers);
        // Slice the first 6
        const sixFeatured = shuffled.slice(0, 6);

        setFeatured(sixFeatured);
      } catch (error) {
        console.error("Error fetching job seekers:", error);
        // Optionally handle or display error
      }
    };

    fetchRandomFeatured();
  }, []);

  // Simple shuffle utility
  function shuffleArray(array) {
    const arr = [...array];
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  }

  // Toggle favorite logic (like in Pitches.jsx)
  const toggleFavorite = (id) => {
    setFavorites((prevFavs) => ({
      ...prevFavs,
      [id]: !prevFavs[id],
    }));
  };

  return (
    <div className="pitches-container">
      <div className="pitches-header">
        <h2>Featured Pitches</h2>
      </div>
      <div className="pitches-content">
        <div className="pitches-grid">
          {featured.map((user) => {
            // Combine city + state for display
            const userLocation = user.state
              ? `${user.city}, ${user.state}`
              : user.city || "N/A";

            return (
              <div className="pitch-card" key={user.id}>
                {/* If you do store a videoUrl in the DB, render <video>. Otherwise a fallback: */}
                <p>No pitch video available</p>

                <div className="pitch-info">
                  <div>
                    <h3>{user.name}</h3>
                    <p>
                      <strong>Job:</strong> {user.currentrole || "N/A"}
                    </p>
                    <p>
                      <strong>Skills:</strong> {user.skills || "N/A"}
                    </p>
                    <p>
                      <strong>Location:</strong> {userLocation}
                    </p>
                    <p>{user.shortBio || ""}</p>
                  </div>
                  <div className="pitch-actions">
                    <Link
                      to={`/profile/${user.id}`}
                      className="view-profile-button"
                    >
                      View Profile
                    </Link>
                    <button
                      className={`favorite-btn ${
                        favorites[user.id] ? "favorited" : ""
                      }`}
                      onClick={() => toggleFavorite(user.id)}
                    >
                      <FaStar />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FeaturedPitches;
