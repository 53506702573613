import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import logoNew from './logSavon.png';

function NavBar({ loggedIn, onLogout }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleLogout = () => {
    // Clear user data from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userName');

    // Call onLogout to update parent state
    onLogout();

    // Close the menu if open and redirect to homepage
    closeMenu();
    navigate('/');
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/" onClick={closeMenu}>
          <img src={logoNew} alt="Elevator Pitches Logo" className="navbar-logo-image" />
        </Link>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <ul className={`navbar-menu ${menuOpen ? 'show' : ''}`}>
        <li>
          <Link to="/" onClick={closeMenu}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/pitches" onClick={closeMenu}>
            Pitches
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={closeMenu}>
            About
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </li>

        {!loggedIn && (
          <>
            <li>
              <Link to="/login" onClick={closeMenu}>
                Login
              </Link>
            </li>
            <li>
              <Link to="/register" className="navbar-get-started" onClick={closeMenu}>
                Get Started
              </Link>
            </li>
          </>
        )}

        {loggedIn && (
          <>
            <li>
              <Link to="/job-seeker" onClick={closeMenu}>
                Dashboard
              </Link>
            </li>
            <li>
              <button 
                className="navbar-logout" 
                onClick={handleLogout}
              >
                Logout
              </button>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}

export default NavBar;
